import React from 'react';
import './InfoCtaPanel.scss';
import Buttons from '../Buttons/Buttons';
import tt from '../../helpers/translation';

const InfoCtaPanel = ({ buttons }) => {
  return (
    <div className="button-panel">
      {buttons.map((button) => (
        <div className={`button_item ${button.border ? 'border' : null}`}>
          <div
            className="button_text"
            dangerouslySetInnerHTML={{
              __html: button.description,
            }}
          />

          {button.ctaText === 'proximamente' ? (
            <h3
              style={{
                fontWeight: 'bold',
                fontSize: '12px',
                color: '#5e5e5e',
                textAlign: 'center',
              }}
            >
              {tt('PRÓXIMAMENTE', button.locale)}
            </h3>
          ) : (
            <Buttons
              link={button.cta}
              size={button.size}
              color={button.color}
              text={button.ctaText}
              blank={button.blank}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default InfoCtaPanel;
